import ThorEN from "../static/videos/ThorEN.mp4";
import ThorES from "../static/videos/ThorEN-1.mp4";
import NarutoEN from "../static/videos/NarutoEN.mp4";
import NarutoES from "../static/videos/NarutoES.mp4";

/* const BASE_URL =
  "/static/media/videos";

export const ThorArrivesWakandaEN = BASE_URL+"/ThorEN.cd3bd7cae7054bb5a630.mp4";
export const ThorArrivesWakandaES = BASE_URL+"/ThorES.cd3bd7cae7054bb5a630.mp4";
export const NarutoAndSasukeVsMomoshikiEN = BASE_URL+"/NarutoEN.ea6c18d269c6beda2aa3.mp4";
export const NarutoAndSasukeVsMomoshikiES = BASE_URL+"/NarutoES.64ada0ad3f1f096e0f6f.mp4";
 */

export const ThorArrivesWakandaEN = ThorEN;
export const ThorArrivesWakandaES = ThorES;
export const NarutoAndSasukeVsMomoshikiEN = NarutoEN;
export const NarutoAndSasukeVsMomoshikiES = NarutoES;